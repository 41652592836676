<template>
  <v-row>

    <v-col cols="12">
      <area-actualizar ref="AreaActualizarRef"  @GetActualizarLista="GetActualizarLista"></area-actualizar>
    </v-col>

    <v-col cols="12">
      <area-listar ref="AreaListarRef" @GetEditar="GetEditar"></area-listar>
    </v-col>
  </v-row>
</template>
  
<script>
import AreaListar from './componentes/AreaListar.vue'
import AreaActualizar from './componentes/AreaActualizar.vue';
import { ref } from '@vue/composition-api'

export default {
  components: {
    AreaListar,
    AreaActualizar

  },

  setup() {
    const datos = ref({
      "id": 0,
      "idTipoArea": 0,
      "idAreaPadre": 0,
      "idSucursal": 0,
      "indActivo": true,
      "nombre": "",
      "descripcion": ""
    })
    const AreaActualizarRef = ref(null)
    const AreaListarRef = ref(null)
    
    const GetActualizarLista = (item) => {
      AreaListarRef.value.cargarArea(item)
    }
    const GetEditar = (item) => {
      AreaActualizarRef.value.CargarEditar(item)
    }

    return {
      GetEditar,
      AreaActualizarRef,
      GetActualizarLista,
      AreaListarRef
    }
  }
}
</script>
  