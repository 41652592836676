//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "..";
import router from '@/router'

class AreaServices {
    AreaConsultar(
        datos = {
            Id: -1,
            IdTipoArea: -1,
            IdAreaPadre: -1,
            IdSucursal: -1,
            IndActivo: null,
            IndAreaPrincial: null
        }
    ) {
        return http.get("area/consultar", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    } 

    TipoAreaConsultar(
        datos = {
            Id: -1,
            Descripcion: ""
        }
    ) {
        return http.get("area/tipoarea/consultar", { params: datos })
            .catch((error) => {
                if (error.response.status == 401) {
                    store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
                    router.push("/pages/login");
                }
            });
    }

    AreaActualizar(
        idUsuario,
        id,
        idTipoArea ,
        idAreaPadre,
        idSucursal ,
        indActivo ,
        nombre ,
        descripcion 
    ) {   
        const datos = {
            IdUsuario: idUsuario,
            Data: {
              Id: id,
              IdTipoArea: idTipoArea,
              IdAreaPadre: idAreaPadre,
              IdSucursal: idSucursal,
              IndActivo: indActivo,
              Nombre: nombre,
              Descripcion:descripcion
            }
          }
          console.log(datos) 
        return http.post("/area/AreaActualizar",  datos )
          .catch((error) => {
            if (error.response.status == 401) {
              store.commit("setUserData", { id: 0, userName: "", isAutenticated: false });
              router.push("/pages/login");
            }
          });
      }
}


export default new AreaServices();