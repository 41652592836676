<template>
    <v-card flat class="pa-3 mt-2">
        <v-card-subtitle>
            Area/Almacen
        </v-card-subtitle>
        <v-form ref="form" lazy-validation>
            <v-card-text class="d-flex">
                <v-row>
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12" md="6">

                                <v-select :loading="cargandoAreas" v-model="datos.idTipoArea" :items="tipoAreas"
                                    item-text="descripcion" item-value="id" :rules="reglas.IdTipoArea"
                                    label="Tipo Area*" required hide-details="auto" class="mb-6 mt-5" outlined
                                    dense></v-select>

                                <v-select :loading="cargandoTipoAreas" v-model="datos.idAreaPadre" :items="areas"
                                    item-text="nombre" item-value="id" label="Area Padre" hide-details="auto"
                                    class="mb-6  " outlined dense></v-select>
                                <v-switch v-model="datos.indActivo" :label="`Ind Activo`"></v-switch>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field :rules="reglas.Nombre" v-model="datos.nombre" label="Nombre *" required
                                    outlined dense hide-details="auto" class="mb-6 mt-5"></v-text-field>

                                <v-textarea outlined label="Descripcion" v-model="datos.descripcion"></v-textarea>


                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="text-right">
                        <v-btn color="secondary" class="me-3  " @click="limpiar()" small> Limpiar
                        </v-btn>
                        <v-btn color="primary" class="me-3  " small @click="Guardar()"> Guardar </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-form>
    </v-card>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import AreaServices from '@/api/servicios/AreaServices'
import store from '@/store'
import goTo from 'vuetify/lib/services/goto'
export default {
    watch: {
        '$store.state.sucursalSelect.id': function ( ) { 
            this.cargarArea()  
        }
    }, 
    setup(props, context) {
        const tipoAreas = ref([])
        const areas = ref([])
        const guardando = ref(false)
        const form  = ref(null)
        const reglas = {
            IdTipoArea: [

                v => !!v || "Tipo area es requerido"
            ],
            Nombre: [

                v => !!v || "Nombre es requerido"
            ]
        }
        const datos = ref({
            "id": -1,
            "idTipoArea": 1,
            "idAreaPadre": 0,
            "idSucursal": 0,
            "indActivo": true,
            "nombre": "",
            "descripcion": ""
        })


        const limpiar = () => {
            datos.value = {
                "id": -1,
                "idTipoArea": 1,
                "idAreaPadre": 0,
                "idSucursal": 0,
                "indActivo": true,
                "nombre": "",
                "descripcion": ""
            };
            form.value.resetValidation();
        }


        onBeforeMount(() => {
            cargarArea()
            cargarTipoArea()
        })
        const cargandoAreas = ref(false)
        const cargandoTipoAreas = ref(false)

        const cargarArea = () => {
            cargandoAreas.value = true
            try {

                AreaServices.AreaConsultar({
                    Id: -1,
                    IdTipoArea: -1,
                    IdAreaPadre: -1,
                    IdSucursal: store.state.sucursalSelect.id,
                    IndActivo: true,
                    IndAreaPrincial: null
                }).then(response => {
                    console.log(response)
                    if (response.data.estatus == true) {
                        areas.value = [{ id: 0, nombre: "Sin area padre" }, ...response.data.datos]
                    }
                })
                    .catch(error => {
                        console.log(error)
                    })
                    .finally(() => {
                        cargandoAreas.value = false
                    })


            } catch (error) {
                //alert(error)
                cargandoAreas.value = false
            }

        }
        const cargarTipoArea = () => {
            cargandoTipoAreas.value = true
            try {

                AreaServices.TipoAreaConsultar().then(response => {
                    console.log(response)
                    if (response.data.estatus == true) {
                        tipoAreas.value = response.data.datos
                    }
                })
                    .catch(error => {
                        console.log(error)
                    })
                    .finally(() => {
                        cargandoTipoAreas.value = false
                    })


            } catch (error) {
                //alert(error)
                cargandoTipoAreas.value = false
            }

        }
        const CargarEditar = (item) => {
            datos.value = item
            goTo(0)
        }
        const validarForm = () => {
            let val = form.value?.validate()

            return val
        }
        const ActualizarLista = item => {
            console.log(item)
            context.emit('GetActualizarLista', { ...item })
            
        }
        const Guardar = () => {
            guardando.value = true
            if (validarForm()) {
                try {

                    AreaServices.AreaActualizar( 
                        store.state.user.idUsuario,
                        datos.value.id ,
                        datos.value.idTipoArea ,
                        datos.value.idAreaPadre ,
                        store.state.sucursalSelect.id ,
                        datos.value.indActivo ,
                        datos.value.nombre ,
                        datos.value.descripcion  
                      ).then(response => {
                        console.log(response)
                        if (response.data.estatus == true) {
                            store.commit('setAlert', {
                                message: response.data.mensaje,
                                type: 'success',
                            })
                            ActualizarLista(response.data.datos)
                            limpiar()
                        } else {
                            
                            store.commit('setAlert', {
                                message: `${response.data.mensaje}: `+response.data.datos.mensajeError ,
                                type: 'warning',
                            })
                        }
                    })
                        .catch(error => {
                            console.log(error)
                            store.commit('setAlert', {
                                message: error,
                                type: 'error',
                            })
                        })
                        .finally(() => {
                            guardando.value = false
                        })
                } catch (error) {
                    //alert(error)
                    store.commit('setAlert', {
                        message: error,
                        type: 'error',
                    })
                }
            } else {
                store.commit('setAlert', {
                    message: 'Verifique que todos los datos estén completos',
                    type: 'warning',
                })
            }

        }
        return {
            tipoAreas,
            reglas,
            datos,
            CargarEditar,
            areas,
            cargarTipoArea,
            cargandoAreas,
            cargandoTipoAreas,
            limpiar,
            Guardar,
            form ,
            ActualizarLista,
            cargarArea
        }
    }
}

</script>